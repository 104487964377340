import log from 'loglevel';
import styled from '@emotion/styled';

import {
  slideInDownCTAAnimation,
  slideInDownErrorAnimation,
  slideInDownSuccessAnimation,
  slideOutUpAnimation,
} from './animations';

type AnimationWrapperProps = {
  isDismissed: boolean;
  type: 'error' | 'success' | 'cta';
  children: React.ReactNode;
};

function getSlideInDownAnimation(type: AnimationWrapperProps['type']) {
  switch (type) {
    case 'error':
      return slideInDownErrorAnimation;
    case 'success':
      return slideInDownSuccessAnimation;
    case 'cta':
      return slideInDownCTAAnimation;
    default:
      log.warn(
        `[getAnimationByType]: Animation type <${type}> not supported. No animation applied.`,
      );
      return undefined;
  }
}

const AnimationWrapper = styled.div<AnimationWrapperProps>(
  ({ theme, isDismissed, type }: AnimationWrapperProps & { theme: any }) => {
    return {
      opacity: 0,
      zIndex: 100,
      animation: isDismissed
        ? slideOutUpAnimation
        : getSlideInDownAnimation(type),
      padding: `${theme?.space?.[3]} 0`,
      color: 'transparent',
      position: 'absolute',
      width: '100%',
    };
  },
);

export default AnimationWrapper;
