import { Cloud$PurchaseModelCode } from '@otovo/shared/types/cloudApi';
/* eslint-env browser */
import { stripURL } from '..';

import { Trackers, cleanTrackerIds } from './trackerUtils';
import {
  gaInit,
  pageview as gaPageview,
  sale as gaSale,
  event as gaEvent,
  EventType,
} from './ga';

import { fbInit, track as fbTrack, sale as fbSale } from './fb';

import {
  dataLayerPush as gtmDataLayerPush,
  dataLayerPageView as gtmDataLayerPageView,
} from './gtm';

let gtmEnabled = null;

/*
  Track page views to GA.

  Facebook automatically tracks changes to page views so the track function in
  fb.js should not be called. We would otherwise track double page views.
  A caveat is that the Facebook pixel requires the URL to change to track a page
  view. Read more at https://developers.facebook.com/ads/blog/post/v2/2017/05/29/tagging-a-single-page-application-facebook-pixel/
*/
export function logPageView(href: string) {
  const stripped = stripURL(href);
  if (!href.startsWith('/')) {
    throw new Error(`href's must start with '/'. Was ${stripped}`);
  }

  if (gtmEnabled) {
    gtmDataLayerPageView(stripped);
  } else {
    gaPageview(stripped, { isVirtual: false });
  }
}

const loggedEvents = [];

function getLogKeyFromEventType({
  category,
  action,
  label,
}: EventType): string {
  return `${category}-${action}-${label || ''}`;
}

function isLogged(logKey: string): boolean {
  return loggedEvents.includes(logKey);
}

function markAsLogged(logKey: string) {
  loggedEvents.push(logKey);
}

export function logVirtualPageView(pagePath: string, once = false) {
  if (!gtmEnabled) {
    return;
  }

  if (once && isLogged(pagePath)) {
    return;
  }

  markAsLogged(pagePath);
  gtmDataLayerPageView(pagePath);
}

/*
  Logs a "raw" event to Google Analytics (GA). Be mindful of 'category' to keep
  high readability in GA, and to avoid clashing with Google Tag Manager events.

  DEPRECATED. Use logUIEvent instead
*/
export function logEvent({ category, action, label = '', ...rest }: EventType) {
  if (gtmEnabled) {
    return;
  }
  gaEvent({
    ...rest,
    category,
    action,
    label: String(label),
  });
}

/*
  Logs a user behavior event.

  Use 'action' to indicate what happened, for example:
    "User zoomed in on the map in the custom surface flow"

  Use `context` if you send the same action with multiple contexts; For example if a
  component is placed more than one place, and you'd like to distinguish that
  in the event.

  Event 1:
    action: 'User zoomed in on the map'
    context: 'Custom surface flow'

  Event 2:
    action: 'User zoomed in on the map'
    context: 'Calculation page'
*/
export function logUIEvent(
  { action, context = '' }: { action: string; context?: string },
  once = false,
) {
  const category = 'UI';
  const label = context;

  const logKey = getLogKeyFromEventType({ category, action, label });
  if (once && isLogged(logKey)) {
    return;
  }

  if (once) {
    markAsLogged(logKey);
  }

  if (gtmEnabled) {
    gtmDataLayerPush({
      event: 'UI events',
      eventCategory: category,
      eventAction: action,
      eventLabel: context,
    });
  } else {
    logEvent({
      category: 'UI',
      action,
      label,
    });
  }
}

const PRODUCT_TYPE = 'solar';

function logQualifiedInterest() {
  if (gtmEnabled) {
    gtmDataLayerPush({
      event: 'Solar Qualified Activation',
      eventCategory: 'Solar',
      eventAction: 'Qualified Activation',
      eventLabel: 'Post Activation',
    });
  }
}

export function qualifyUserFromPostActivation(market: string, value: string) {
  const excludingConstraints = {
    it: ['it_ecobonus_110_with_paperwork'],
    es: ['apartment'],
    de: ['renter'],
  };
  if (!excludingConstraints[market]) {
    return;
  }

  if (excludingConstraints[market].find((contraint) => contraint === value)) {
    return;
  }

  logQualifiedInterest();
}

export function logInterest() {
  logUIEvent({
    action: 'User created an interest',
  });

  if (gtmEnabled) {
    gtmDataLayerPush({
      event: 'Solar Interest',
      eventCategory: 'Solar',
      eventAction: 'Interest',
      eventLabel: 'Lead',
    });
  } else {
    gaEvent({
      category: PRODUCT_TYPE,
      action: 'interest',
      label: 'Lead',
    });

    fbTrack('Lead', {
      content_category: PRODUCT_TYPE,
      content_name: 'interest',
    });
  }
}

type LogActivationType = {
  purchaseModelCode: Cloud$PurchaseModelCode;
};
export function logActivation({ purchaseModelCode }: LogActivationType) {
  logUIEvent({
    action: 'User activated an interest',
    context: `With purchase model ${purchaseModelCode}`,
  });
  if (gtmEnabled) {
    gtmDataLayerPush({
      event: 'Solar Activation',
      eventCategory: 'Solar',
      eventAction: 'Activation',
      eventLabel: 'Lead',
    });
  } else {
    gaEvent({
      label: 'Lead',
      category: PRODUCT_TYPE,
      action: 'activation',
    });

    fbTrack('Lead', {
      content_category: PRODUCT_TYPE,
      content_name: 'activation',
    });
  }
}

export function logSale(id: string) {
  logUIEvent({
    action: 'User proceeded to signing the offer',
  });
  if (gtmEnabled) {
    gtmDataLayerPush({
      event: 'Customer Purchase',
      eventCategory: 'Solar',
      eventAction: 'Sale',
      eventLabel: 'Purchase',
    });
  } else {
    gaSale({
      id,
      category: PRODUCT_TYPE,
    });

    fbSale({
      contentType: PRODUCT_TYPE,
    });
  }
}

export function logCalculationShown() {
  if (!gtmEnabled) {
    return;
  }
  gtmDataLayerPush({
    event: 'Calculation Shown',
    eventCategory: 'Solar',
    eventAction: 'Calculation',
    eventLabel: 'Lead',
  });
}

export function logContactMe() {
  if (!gtmEnabled) {
    return;
  }
  gtmDataLayerPush({
    event: 'Solar Activation Form',
    eventCategory: 'Solar',
    eventAction: 'Form',
    eventLabel: 'Lead',
  });
}

export function logSurfaceSubmitted() {
  logUIEvent({
    action: 'User successfully submitted surface',
  });
  if (gtmEnabled) {
    gtmDataLayerPush({
      event: 'Surface submitted',
      eventCategory: 'Custom Surface UI',
      eventAction: 'Submit Surface Succeeded',
      eventLabel: 'Custom Surface Submit',
    });
  }
}

type AnalyticsConfig = {
  FACEBOOK_PIXEL_IDS?: Trackers;
  GOOGLE_ANALYTICS_IDS?: Trackers;
  GOOGLE_TAG_MANAGER_ENABLED: boolean;
};

let initialized = false;
export function initAnalytics(
  analyticsConfig: AnalyticsConfig,
  getClientId?: () => Promise<void | string>,
) {
  if (!__isBrowser__ || initialized) {
    // We only support client side analytics
    return;
  }
  initialized = true;

  gtmEnabled = analyticsConfig.GOOGLE_TAG_MANAGER_ENABLED;
  if (gtmEnabled) {
    return;
  }

  const gaTrackers = cleanTrackerIds(analyticsConfig.GOOGLE_ANALYTICS_IDS);
  if (gaTrackers.length) {
    gaInit({
      gaIds: gaTrackers,
      getClientId,
    });
  }

  const fbTrackers = cleanTrackerIds(analyticsConfig.FACEBOOK_PIXEL_IDS);
  if (fbTrackers.length) {
    fbInit({ fbIds: fbTrackers });
  }
}
