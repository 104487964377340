import {
  Surface$Coordinates,
  Surface$Store,
} from '@otovo/shared/types/surfaceTypes';
import {
  RESET_STATE,
  REQUEST_CREATE_SURFACE,
  REQUEST_CREATE_SURFACE_SUCCESS,
  REQUEST_CREATE_SURFACE_FAILED,
  SELECT_HOUSE,
} from './actionTypes';

const initialState = {
  isPosting: false,
  postFailed: false,
  postSuccess: false,
  selectedHouseCoordinates: {},
};

type ActionTypes =
  | { type: typeof RESET_STATE }
  | { type: typeof REQUEST_CREATE_SURFACE }
  | { type: typeof REQUEST_CREATE_SURFACE_SUCCESS }
  | { type: typeof REQUEST_CREATE_SURFACE_FAILED }
  | {
      type: typeof SELECT_HOUSE;
      coordinates: Surface$Coordinates;
    };

export default function surfaceReducer(
  state: Surface$Store | undefined = initialState,
  action: ActionTypes,
): Surface$Store {
  switch (action.type) {
    case RESET_STATE:
      return initialState;
    case REQUEST_CREATE_SURFACE:
      return {
        ...state,
        isPosting: true,
        postFailed: false,
      };
    case REQUEST_CREATE_SURFACE_SUCCESS:
      return {
        ...state,
        isPosting: false,
        postSuccess: true,
      };
    case REQUEST_CREATE_SURFACE_FAILED:
      return {
        ...state,
        isPosting: false,
        postFailed: true,
      };
    case SELECT_HOUSE:
      return {
        ...state,
        selectedHouseCoordinates: {
          ...action.coordinates,
        },
      };

    default:
      return state;
  }
}
