import { Cloud$FormValidationErrors } from '@otovo/shared/types/cloudApi';
import {
  REQUEST_ACCEPT_CALCULATION,
  REQUEST_ACCEPT_CALCULATION_SUCCESS,
  REQUEST_ACCEPT_CALCULATION_FAILED,
} from './actionTypes';

import { CalculationStore } from './flowTypes';

const initialState: CalculationStore = {
  requestingAcceptCalculation: false,
  requestingAcceptCalculationFailed: false,
  requestingAcceptCalculationSuccess: false,
  acceptedCalculationPriceId: null,
  responseStatus: null,
  errorResponse: null,
};

type ActionTypes =
  | { type: typeof REQUEST_ACCEPT_CALCULATION }
  | { type: typeof REQUEST_ACCEPT_CALCULATION_SUCCESS; price_id?: string }
  | {
      type: typeof REQUEST_ACCEPT_CALCULATION_FAILED;
      responseStatus: number;
      json: Cloud$FormValidationErrors;
    };

function reducer(
  state: CalculationStore | undefined = initialState,
  action: ActionTypes,
): CalculationStore {
  switch (action.type) {
    case REQUEST_ACCEPT_CALCULATION:
      return {
        ...state,
        requestingAcceptCalculation: true,
        requestingAcceptCalculationFailed: false,
        responseStatus: null,
        errorResponse: null,
      };
    case REQUEST_ACCEPT_CALCULATION_SUCCESS:
      return {
        ...state,
        acceptedCalculationPriceId:
          typeof action.price_id === 'string' ? action.price_id : null,
        requestingAcceptCalculation: false,
        requestingAcceptCalculationSuccess: true,
      };
    case REQUEST_ACCEPT_CALCULATION_FAILED:
      return {
        ...state,
        requestingAcceptCalculation: false,
        requestingAcceptCalculationFailed: true,
        responseStatus: action.responseStatus,
        errorResponse: action.json,
      };
    default:
      return state;
  }
}

export default reducer;
