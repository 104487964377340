/* eslint-env browser */
import log from 'loglevel';
import { logToSentry } from '../../sentry';

export function dataLayerPush(payload: Record<string, string>) {
  if (!window.dataLayer) {
    log.warn(
      `Tried to push to the data layer but it don't exist: ${JSON.stringify(
        payload,
      )}`,
    );
    return;
  }

  window.dataLayer.push(payload);
}

function getLastVirtualPageViewPath() {
  if (!window.dataLayer) {
    return null;
  }

  const pageViews = window.dataLayer.filter(
    (entry) => entry.event === 'virtualPageview',
  );

  if (!pageViews.length) {
    return null;
  }

  const lastPageView = pageViews[pageViews.length - 1];

  if (!lastPageView) {
    return null;
  }

  return lastPageView.pagePath;
}

const exclusions = [/\/redirect\//, /generate-outline/];
export function dataLayerPageView(pagePath: string) {
  if (exclusions.some((exclusion) => exclusion.test(pagePath))) {
    return;
  }

  if (getLastVirtualPageViewPath() === pagePath) {
    // Don't log the same page view twice
    return;
  }

  dataLayerPush({
    event: 'virtualPageview',
    pagePath,
  });
}

function onlyUnique<Type>(value: Type, index: number, ary: Array<Type>) {
  return ary.indexOf(value) === index;
}

export function getClientIdFromTracker(): any | null {
  const { ga } = window;

  if (!ga || typeof ga.getAll !== 'function') {
    // "getAll exists only in a GTM context
    // See https://stackoverflow.com/questions/23760595/google-analytics-send-event-callback-function
    // if you need the client id also in a non-GTM context.
    return null;
  }

  const trackers = ga.getAll();
  const uniqueClientIds = trackers
    .map((tracker) => tracker.get('clientId'))
    .filter(onlyUnique);

  if (uniqueClientIds.length > 1) {
    logToSentry(
      `Tried to fetch client ids, and found ${uniqueClientIds.length} different values. Using the first one.`,
    );
  }

  return uniqueClientIds[0];
}
