import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import WeekIso from 'dayjs/plugin/isoWeek';
import WeekOfYear from 'dayjs/plugin/weekOfYear';
import { Otovo$Config } from '../types/otovoweb';

dayjs.extend(AdvancedFormat); // use plugin
dayjs.extend(WeekOfYear); // use plugin
dayjs.extend(WeekIso); // use plugin

export function setLocale(locale: Otovo$Config['BU_CONFIG']['locale']) {
  /* eslint-disable global-require */
  switch (locale) {
    case 'de-at':
      require('dayjs/locale/de-at');
      dayjs.locale('de-at');
      break;
    case 'nb-no':
      require('dayjs/locale/nb');
      dayjs.locale('nb');
      break;
    case 'sv-se':
      require('dayjs/locale/sv');
      dayjs.locale('sv');
      break;
    case 'es-es':
      require('dayjs/locale/es');
      dayjs.locale('es');
      break;
    case 'pt-br':
      require('dayjs/locale/pt-br');
      dayjs.locale('pt-br');
      break;
    case 'fr-fr':
    case 'be-fr':
      require('dayjs/locale/fr');
      dayjs.locale('fr');
      break;
    case 'en-gb':
      require('dayjs/locale/en');
      dayjs.locale('en');
      break;
    case 'it-it':
      require('dayjs/locale/it');
      dayjs.locale('it');
      break;
    case 'pl-pl':
      require('dayjs/locale/pl');
      dayjs.locale('pl');
      break;
    case 'de-de':
      require('dayjs/locale/de');
      dayjs.locale('de');
      break;
    case 'pt-pt':
      require('dayjs/locale/pt');
      dayjs.locale('pt');
      break;
    case 'nl-nl':
      require('dayjs/locale/nl');
      dayjs.locale('nl');
      break;
    case 'nl-be':
      require('dayjs/locale/nl-be');
      dayjs.locale('nl-be');
      break;
    case 'de-ch':
      require('dayjs/locale/de-ch');
      dayjs.locale('de-ch');
      break;
    case 'fr-ch':
      require('dayjs/locale/fr-ch');
      dayjs.locale('fr-ch');
      break;
    case 'it-ch':
      require('dayjs/locale/it-ch');
      dayjs.locale('it-ch');
      break;
    default:
      throw new Error(`Unknown locale ${locale}`);
  }
  /* eslint-enable global-require */
}

// Ordinal numbers (Do) are not displayed correctly in Sweden or Spain.
// We can remove the conditional expressions if DayJS is fixed or remove
// use of "Do" for Norway.
export const getLongDateFormat = (): string => {
  return dayjs.locale() === 'nb' ? 'dddd Do MMMM' : 'dddd D MMMM';
};

export const getDayMonthYearFormat = (): string => {
  return dayjs.locale() === 'nb' ? 'Do MMMM YYYY' : 'dddd D MMMM';
};

export const getShortDateFormat = (): string => {
  return dayjs.locale() === 'nb' ? 'Do MMMM' : 'D MMMM';
};

export const getWeekdayDayFormat = (): string => {
  return dayjs.locale() === 'nb' ? 'dddd Do' : 'dddd D';
};

export const dateFormat = (
  dateString: string | Date,
  pattern: string = getShortDateFormat(),
): string => {
  const date = dayjs(dateString);
  return date.format(pattern);
};

export default dayjs;
