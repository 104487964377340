import { Otovo$Config } from '@otovo/shared/types/otovoweb';
import { createContext, useContext } from 'react';

export const OtovoConfigContext = createContext<Otovo$Config | void>(null);

export function useGlobalConfig(): Otovo$Config {
  const config = useContext(OtovoConfigContext);

  if (config === undefined) {
    throw new Error(
      'useGlobalConfig must be wrapped in a <OtovoConfigContext.Provider> component',
    );
  }

  if (!config || !Object.keys(config).length) {
    throw new Error(
      `Context provider has been initialized with invalid config: <OtovoConfigContext.Provider value={${typeof config}}>`,
    );
  }

  return config;
}
