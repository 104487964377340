import { AnyAction, combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';

import interestStore from 'otovoweb/src/stores/Interest/reducer';
import { InterestStore } from 'otovoweb/src/stores/Interest/type';
import fileUploaderReducer, {
  FileUploaderStore,
} from 'otovoweb/src/components/FileUploader/reducer';
import loanReducer, { LoanStore } from 'otovoweb/src/stores/Loan/reducer';

import { Surface$Store } from '@otovo/shared/types/surfaceTypes';

import contractStore, {
  ContractStore,
} from 'otovoweb/src/stores/Contract/reducer';

import surfaceStore from 'otovoweb/src/stores/Surface/reducer';
import addressStore, {
  AddressStore,
} from 'otovoweb/src/stores/Address/reducer';
import userProfileReducer, {
  UserProfileStore,
} from 'otovoweb/src/stores/UserProfile/reducer';

import calculationReducer from './stores/Calculation/reducer';
import { CalculationStore } from './stores/Calculation/flowTypes';

export type BreezeReducers = {
  addressStore: AddressStore;
  calculationStore: CalculationStore;
  contractStore: ContractStore;
  fileUploaderState: FileUploaderStore;
  interestStore: InterestStore;
  loanStore: LoanStore;
  surfaceStore: Surface$Store;
  userProfile: UserProfileStore;
};

const allReducers = combineReducers({
  addressStore,
  calculationStore: calculationReducer,
  contractStore,
  fileUploaderState: fileUploaderReducer,
  interestStore,
  loanStore: loanReducer,
  surfaceStore,
  userProfile: userProfileReducer,
});

const rootReducer = (
  state: BreezeReducers,
  action: AnyAction,
): BreezeReducers => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state,
      ...action.payload,
    };
    return nextState;
  }
  return allReducers(state, action);
};

export default rootReducer;
