import { Router } from 'next/router';
import {
  initAnalytics,
  logPageView,
} from 'otovoweb/src/lib/analytics/analytics';
import { useEffect } from 'react';
import { Otovo$Config } from '../types/otovoweb';

type Args = {
  config: Otovo$Config;
  path: string;
  getClientId?: () => Promise<void | string>;
  isGTMEnabled: boolean;
};

export function useAnalytics({
  config,
  path,
  getClientId,
  isGTMEnabled,
}: Args) {
  initAnalytics(
    {
      GOOGLE_ANALYTICS_IDS: [
        process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
        config?.GOOGLE_ANALYTICS_ID,
      ],
      FACEBOOK_PIXEL_IDS: [
        process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
        config?.FACEBOOK_PIXEL_ID,
      ],
      GOOGLE_TAG_MANAGER_ENABLED: isGTMEnabled,
    },
    getClientId,
  );
  useEffect(() => {
    logPageView(path); // Log initial page view
    // ^ this assumes initAnalytics is completed which won't be the case for the iframe.
    // However, this is actually a feature right now (prototype)
    const handleRouteChange = (url: string) => {
      logPageView(url);
    };
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
    // Run the useEffect code once, and once only.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
