module.exports = [
  // Random plugins/extensions
  'top.GLOBALS',

  // User offline or API restarting
  'Network request failed',

  // Smooch
  'node_modules/smooch/',

  // Leaflet
  'node_modules/leaflet/',

  // Microsoft Office 365 previews (https://github.com/getsentry/sentry-javascript/issues/3440)
  'Non-Error promise rejection captured with value: Object Not Found Matching',

  'SecurityError: Blocked a frame with origin',
  // e.g. SecurityError: Blocked a frame with origin "https://www.otovo.no" from accessing a cross-origin frame.

  // People saving pages and opening them locally
  'file://',

  "SecurityError: Failed to execute 'open' on 'XMLHttpRequest': Refused to connect to 'https://plugin.ucads.ucweb.com",
  "SecurityError: Failed to execute 'sendBeacon' on 'Navigator': Refused to send beacon to",

  // Aborted network requests on the client
  'AbortError: The operation was aborted',
  'TypeError: cancelled',
  'AbortError: The user aborted a request',
  'Unable to get external config from https://api.otovo.com/web/config. AbortError: The user aborted a request.',

  // Network issues
  '(timeout: ', // Timeouts while waiting for a chunk is likely a client network issue.
  'Failed to fetch',

  // Errors in other languages than english that we can ignore
  'TypeError: avbruten',
  'TypeError: Zeitüberschreitung bei der Anforderung',
  'TypeError: Mistet nettverksforbindelsen',
  'TypeError: Handlingen kunne ikke fullføres. Programvaren forårsaket forbindelsesavbrudd',
  'TypeError: Nätverksanslutningen förlorades',
  'TypeError: Internett ser ut til å være frakoblet',
  'TypeError: Fant ikke en tjener med det angitte vertsnavnet',
  'Ugyldig utklippstavleformat',
  'TypeError: cancelado',
  'TypeError: Sertifikatet for denne tjeneren er ugyldig',
  'TypeError: Tidsavbrudd for forespørselen',
  'TypeError: Åtgärden kunde inte slutföras. Program orsakade avbrott i anslutningen',
  'TypeError: Internetanslutningen verkar vara nedkopplad',
  'TypeError: Misslyckades med fetch',
  'Handlingen kunne ikke fullføres. Programvaren forårsaket forbindelsesavbrudd',
  'Hittade ingen server med det angivna värdnamnet.',
  'Begäran nådde en maxtidsgräns.',
  'Kunde inte ansluta till servern.',

  // Browser extensions:
  'safari-extension://',
  'moz-extension://',
  'webkit',
  'jQuery',
  'getValuesOfAutofillInputs',
  'PaymentAutofillConfig',
  'AutoFill',

  // Bing errors
  "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
];
