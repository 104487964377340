export function isSourceKey(key) {
  return ['utm_source', 'cs'].includes(key);
}
export function isMediumKey(key) {
  return ['utm_medium', 'cm'].includes(key);
}
export function isCampaignKey(key) {
  return ['utm_campaign', 'cn'].includes(key);
}
export function isTermKey(key) {
  return ['utm_term', 'ck'].includes(key);
}
export function isContentKey(key) {
  return ['utm_content', 'cc'].includes(key);
}
export function isIdKey(key) {
  return ['utm_id', 'ci'].includes(key);
}
