import storage from '../localStorage';

import referrerParser from './referrerParser';

import {
  isSourceKey,
  isMediumKey,
  isCampaignKey,
  isTermKey,
  isContentKey,
  isIdKey,
} from './utmConfig';

type UtmData = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  utm_id?: string;
};

type Query = {
  utm_source?: string | string[];
  utm_medium?: string | string[];
  utm_campaign?: string | string[];
  utm_term?: string | string[];
  utm_content?: string | string[];
  utm_id?: string | string[];
  referral?: string | string[];
};

export function utmQueryParamsToLocalStorage(query: Query) {
  const expiry = Date.now() + 24 * 3600 * 1000;

  const utmData = {} as UtmData;

  function firstEntry(key: string) {
    const entries = query[key];
    return Array.isArray(entries) ? entries[0] : entries;
  }

  Object.keys(query).forEach((key) => {
    if (isSourceKey(key)) {
      utmData.utm_source = firstEntry(key);
    }
    if (isMediumKey(key)) {
      utmData.utm_medium = firstEntry(key);
    }
    if (isCampaignKey(key)) {
      utmData.utm_campaign = firstEntry(key);
    }
    if (isTermKey(key)) {
      utmData.utm_term = firstEntry(key);
    }
    if (isContentKey(key)) {
      utmData.utm_content = firstEntry(key);
    }
    if (isIdKey(key)) {
      utmData.utm_id = firstEntry(key);
    }
  });

  // Remove old utmData / referral if it changes
  if (Object.keys(utmData).length || query.referral) {
    storage.removeItem('utm_data');
    storage.removeItem('referral');
  }

  if (Object.keys(utmData).length) {
    storage.setItem('utm_data', utmData, expiry);
  }

  const referral = firstEntry('referral');
  if (referral) {
    storage.setItem('referral', referral, expiry);
  }

  if (__isBrowser__) {
    const referrer = referrerParser(document.referrer);
    if (referrer) {
      storage.setItem('referrer', referrer, expiry);
    }
  }
}
