import * as types from './actionTypes';
import { SuggestAddress } from './type';

const initialState = {
  suggestions: [],
  hasError: false,
  isLoading: false,
};

export type AddressStore = {
  suggestions: Array<SuggestAddress>;
  hasError: boolean;
  isLoading: boolean;
};

type Action = {
  type: string;
  json: { query: string; suggestions: Array<SuggestAddress> };
  address: SuggestAddress;
};

export default function addressReducer(
  state: AddressStore | undefined = initialState,
  action: Action,
): AddressStore {
  switch (action.type) {
    case types.REQUEST_ADDRESS_SUGGESTIONS:
      return {
        ...state,
        isLoading: true,
      };
    case types.REQUEST_ADDRESS_SUGGESTIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case types.REQUEST_ADDRESS_SUGGESTIONS_PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case types.REQUEST_ADDRESS_SUGGESTIONS_SUCCESS:
      return {
        suggestions: action.json.suggestions,
        isLoading: false,
        hasError: false,
      };
    case types.RESET_ADDRESS_SUGGESTIONS:
      return initialState;
    default:
      return state;
  }
}
