export function generateWindowDatalayerScript({ query }: { query: string }) {
  function getClientIdFromSearchParam() {
    try {
      return new URLSearchParams(query).get('gaClientId');
    } catch (e) {
      // it's ok
      return null;
    }
  }
  const clientId = getClientIdFromSearchParam();
  let dataLayerContent = `
    'originalLocation': document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search,
    'pagePath': document.location.pathname + document.location.search,
  `;

  if (clientId) {
    dataLayerContent += `
    'clientId': '${clientId}'
    `;
  }

  return `
  window.ga = window.ga || function(){(ga.q=ga.q||[]).push(arguments)};
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ${dataLayerContent}
  });`;
}

export function generateGoogleTagManagerScriptBlock({
  googleTagManagerId,
}: {
  googleTagManagerId: string;
}): string {
  const mainContentScript = `
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
  `;
  return `
    (function(w, d, s, l, i) {
      ${mainContentScript}
    })(window, document, 'script', 'dataLayer', '${googleTagManagerId}');
    `;
}
