const allowUrls = require('./allowUrls');
const ignoreErrors = require('./ignoreErrors');

module.exports = {
  allowUrls,
  ignoreErrors,
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.05,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
};
