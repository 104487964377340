/*
Holds all key/value pairs used by otovoweb's Unleash client.

Are you introducing a new toggle in the code? Add a key/value pair here and
you can start to use it via the `isEnabled(...)` function.
*/

module.exports = {
  ADD_CONSUMPTION_FEATURE_NAME: 'WEB-add-consumption',
  GOOGLE_MAPS_ENABLED: 'WEB-google-maps-sales-flow',
  SANITY_SUBSIDY_NAME: 'WEB-sanity-subsidy',
  NEWSLETTER_CONSENT_FEATURE_NAME: 'WEB-newsletter-consent',
  LEASING_SUBSIDY_HIDDEN: 'WEB-leasing-subsidy-hidden',
  ENABLE_CLOUD_DEBUG_TOOL_NAME: 'WEB-enable-cloud-debug-tool',
  SUPPORT_ARTICLES_ENABLED: 'WEB-support-articles-enabled',
  BATTERY_INFO_ON_CALCPAGE: 'WEB-battery-info-on-calcpage',
  GLOBAL_ALERT: 'WEB-global-alert',
  GOOGLE_OPTIMIZE_ON_LANDING_PAGES_NAME:
    'WEB-google-optimize-on-landing-pages-enabled',
  SAVINGS_EXPERIMENT: 'WEB-savings-experiment',
  POST_ACTIVATION_FORM_ENABLED_FEATURE_NAME: 'WEB-post-activation-form-enabled',
  BATTERY_TOGGLE_IN_SAVINGS: 'WEB-battery-toggle-savings-calc-page',
  FREE_TEXT_ADDRESS_SEARCH_ENABLED: 'free-text-address-search-enabled',
  SHOW_OR_SIMILAR_STANDARD_PANEL_TEXT:
    'WEB-show-or-similar-standard-panel-text',
  SHOW_OR_SIMILAR_PREMIUM_PANEL_TEXT: 'WEB-show-or-similar-premium-panel-text',
  SHOW_OR_SIMILAR_TIER3_PANEL_TEXT: 'WEB-show-or-similar-tier3-panel-text',
  SHOW_OR_SIMILAR_INVERTER_TEXT: 'WEB-show-or-similar-inverter-text',
  SHOW_OR_SIMILAR_BATTERY_TEXT: 'WEB-show-or-similar-battery-text',
  SHOW_OR_SIMILAR_SMART_METER_TEXT: 'WEB-show-or-similar-smart-meter-text',
  SANITY_POST_ACTIVATION_ENABLED: 'sanity_post_activation_enabled',
  WEB_USER_INTERVIEWS: 'WEB-user-interviews',
};
