import log from 'loglevel';

import {
  isCampaignKey,
  isContentKey,
  isIdKey,
  isMediumKey,
  isSourceKey,
  isTermKey,
} from './utmConfig';

type Tracker = {
  trackingId: string;
  gaOptions: {
    name: string;
    cookieFlags: 'samesite=none;secure';
    clientId?: string;
  };
};
type PageViewOpts = {
  isVirtual?: boolean;
};
export type EventType = {
  category: string;
  action: string;
  label?: string;
  nonInteraction?: boolean;
  value?: number;
};
type PageViewType = {
  page: string;
  title: string;
};

async function getReactGA() {
  return (await import('react-ga/core')).default;
}

let initialized = false;
const TRACKER_NAMES = [];
const TRACKER_NAME_PREFIX = 'oTracker';

async function trackPageView(isVirtual: boolean, pageViewObject: PageViewType) {
  if (!isVirtual) {
    (await getReactGA()).set(pageViewObject, TRACKER_NAMES);
  }

  (await getReactGA()).pageview(
    pageViewObject.page,
    TRACKER_NAMES,
    pageViewObject.title,
  );
}

function createTrackers(
  trackerIds: Array<string>,
  clientId?: string | null,
): Array<Tracker> {
  return trackerIds.map((id, index) => {
    const trackerName = `${TRACKER_NAME_PREFIX}${index}`;
    TRACKER_NAMES.push(trackerName);
    return {
      trackingId: id,
      gaOptions: {
        name: trackerName,
        cookieFlags: 'samesite=none;secure',
        clientId: clientId || undefined,
      },
    };
  });
}

type GaInitType = {
  gaIds: Array<string>;
  getClientId?: () => Promise<void | string>;
};
export async function gaInit({
  gaIds,
  getClientId = () => Promise.resolve(),
}: GaInitType) {
  const clientId = await getClientId();

  // @ts-expect-error 2345
  (await getReactGA()).initialize(createTrackers(gaIds, clientId), {
    alwaysSendToDefaultTracker: false,
    debug: false, // Edit this or use the "GA Debug" browser extension (recommended)
    forceSSL: true,
    siteSpeedSampleRate: 50,
    testMode: process.env.NODE_ENV === 'test',
    titleCase: false,
  });
  (await getReactGA()).set({ anonymizeIp: true }, TRACKER_NAMES);
  initialized = true;
}

function fallbackLog(...args) {
  log.info('[GA-debug]', ...args);
}

export function utmToGa(query?: any | null) {
  if (!(query instanceof Object)) {
    throw new Error('UTM query does not have the correct format');
  }

  if (!initialized) {
    return;
  }

  Object.keys(query).forEach(async (key) => {
    if (isSourceKey(key)) {
      (await getReactGA()).set({ campaignSource: query[key] }, TRACKER_NAMES);
    }
    if (isMediumKey(key)) {
      (await getReactGA()).set({ campaignMedium: query[key] }, TRACKER_NAMES);
    }
    if (isCampaignKey(key)) {
      (await getReactGA()).set({ campaignName: query[key] }, TRACKER_NAMES);
    }
    if (isTermKey(key)) {
      (await getReactGA()).set({ campaignKeyword: query[key] }, TRACKER_NAMES);
    }
    if (isContentKey(key)) {
      (await getReactGA()).set({ campaignContent: query[key] }, TRACKER_NAMES);
    }
    if (isIdKey(key)) {
      (await getReactGA()).set({ campaignId: query[key] }, TRACKER_NAMES);
    }
  });
}

export function pageview(
  path: string,
  { isVirtual = false }: PageViewOpts = {},
) {
  if (!initialized) {
    fallbackLog(path);
    return;
  }

  const pageViewObject = {
    page: path,
    title: document.title,
  };

  trackPageView(isVirtual, pageViewObject);
}

export async function event(eventObject: EventType) {
  if (!initialized) {
    fallbackLog(eventObject);
    return;
  }

  (await getReactGA()).event(eventObject, TRACKER_NAMES);
}

export function sale({ category }: { id: string; category: string }) {
  event({
    category,
    action: 'sale',
    label: 'Purchase',
  });
}
