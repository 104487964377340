import {
  Cloud$Interest,
  Cloud$MountingSurfaceSlug,
  Cloud$Package,
  Cloud$PurchaseModel,
} from '@otovo/shared/types/cloudApi';
import { SurfaceConfiguration, InterestStore } from './type';
import {
  CHANGE_SURFACE_ROOFING,
  REQUEST_ADD_CONSUMPTION_FAILED,
  REQUEST_ADD_CONSUMPTION_SUCCESS,
  REQUEST_ADD_CONSUMPTION,
  POLL_INTEREST_CANCELED,
  POLL_INTEREST_COMPLETE,
  POLL_INTEREST_START,
  POLL_UPDATE_INTEREST_START,
  POLL_UPDATE_INTEREST_SUCCESS,
  REQUEST_CREATE_INTEREST_FAILED,
  REQUEST_CREATE_INTEREST_SUCCESS,
  REQUEST_CREATE_INTEREST,
  REQUEST_INTEREST_FAILED,
  REQUEST_INTEREST_SUCCESS,
  REQUEST_INTEREST,
  REQUEST_UPDATE_SURFACES_FAILED,
  REQUEST_UPDATE_SURFACES_SUCCESS,
  REQUEST_UPDATE_SURFACES,
  RESET_INTEREST_STATE,
  SELECT_PACKAGE,
  SELECT_PURCHASE_MODEL,
  TOGGLE_SURFACE,
} from './actionTypes';
import getUserSelections, { UserSelections } from './utils/getUserSelections';

const initialState: InterestStore = {
  fetchFailed: false,
  fetchResponseStatus: null,
  isFetching: false,
  isPosting: false,
  isPolling: false,
  isUpdating: false,
  interest: null,
  selectedPackage: null,
  previousPackage: null,
  selectedProduct: null,
  selectedPurchaseModel: null,
  surfaceConfigurationList: [],
  userSubmittedMonthlyEnergyBill: null,
  postFailed: false,
  postFailedReason: null,
  postFailedStatus: null,
  updateFailed: false,
};
type InterestActionTypes =
  | {
      readonly type: typeof POLL_INTEREST_CANCELED;
      readonly errorMsg: string;
    }
  | {
      readonly type: typeof POLL_INTEREST_START;
      readonly id: string;
    }
  | {
      readonly type: typeof POLL_INTEREST_COMPLETE;
    }
  | {
      readonly type: typeof POLL_UPDATE_INTEREST_START;
    }
  | {
      readonly type: typeof POLL_UPDATE_INTEREST_SUCCESS;
    }
  | {
      readonly type: typeof REQUEST_CREATE_INTEREST;
    }
  | {
      readonly type: typeof REQUEST_CREATE_INTEREST_FAILED;
      readonly json: any;
      readonly responseStatus: number;
    }
  | {
      readonly type: typeof REQUEST_CREATE_INTEREST_SUCCESS;
      readonly json: Cloud$Interest;
    }
  | {
      readonly type: typeof REQUEST_INTEREST_FAILED;
      readonly responseStatus: string;
    }
  | {
      readonly type: typeof REQUEST_INTEREST_SUCCESS;
      readonly json: Cloud$Interest;
    }
  | {
      readonly type: typeof REQUEST_INTEREST;
    }
  | {
      readonly type: typeof REQUEST_UPDATE_SURFACES;
    }
  | {
      readonly type: typeof REQUEST_UPDATE_SURFACES_FAILED;
    }
  | {
      readonly type: typeof REQUEST_UPDATE_SURFACES_SUCCESS;
    }
  | {
      readonly type: typeof REQUEST_ADD_CONSUMPTION;
    }
  | {
      readonly type: typeof REQUEST_ADD_CONSUMPTION_SUCCESS;
      readonly monthlyEnergyBill: number;
    }
  | {
      readonly type: typeof REQUEST_ADD_CONSUMPTION_FAILED;
    }
  | {
      readonly type: typeof RESET_INTEREST_STATE;
    }
  | {
      readonly type: typeof SELECT_PACKAGE;
      readonly selectedPackage: Cloud$Package;
    }
  | {
      readonly type: typeof SELECT_PURCHASE_MODEL;
      readonly selectedPurchaseModel: Cloud$PurchaseModel;
    }
  | {
      readonly type: typeof CHANGE_SURFACE_ROOFING;
      readonly surfaceConfiguration: SurfaceConfiguration;
      readonly roofing: Cloud$MountingSurfaceSlug;
    }
  | {
      readonly type: typeof TOGGLE_SURFACE;
      readonly referenceId: string;
    };

type InitialUserSelections = UserSelections & {
  previousPackage: Cloud$Package;
};

function getInitialSelections(interest: Cloud$Interest): InitialUserSelections {
  let preferBatteryPackage = interest.prefer_battery_package;

  if (interest.experiment_version === 'calc-page-default-with-battery') {
    preferBatteryPackage = true;
  } else if (
    interest.experiment_version === 'calc-page-default-without-battery'
  ) {
    preferBatteryPackage = false;
  }

  const initial = getUserSelections(interest, {
    preferredPurchaseModelCode: interest.default_purchase_model,
    preferBatteryPackage,
  });
  return {
    ...initial,
    previousPackage: initial.selectedPackage,
  };
}

export default function interestReducer(
  state: InterestStore = initialState,
  action: InterestActionTypes,
): InterestStore {
  switch (action.type) {
    case RESET_INTEREST_STATE:
      return { ...initialState };

    case REQUEST_CREATE_INTEREST:
      return {
        ...initialState,
        isPosting: true,
        postFailed: false,
        postFailedReason: '',
      };
    case REQUEST_CREATE_INTEREST_SUCCESS:
      return {
        ...state,
        isPosting: false,
        interest: action.json,
        ...getInitialSelections(action.json),
      };

    case REQUEST_CREATE_INTEREST_FAILED:
      return {
        ...state,
        isPosting: false,
        postFailed: true,
        postFailedStatus: action.responseStatus,
        postFailedReason: action.json,
      };
    case REQUEST_INTEREST:
      return { ...state, isFetching: true, fetchFailed: false };

    case REQUEST_INTEREST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isUpdating: false,
        interest: action.json,
        ...getInitialSelections(action.json),
      };

    case POLL_UPDATE_INTEREST_SUCCESS:
      return { ...state, isPolling: false };

    case REQUEST_INTEREST_FAILED:
      return {
        ...state,
        isFetching: false,
        fetchFailed: true,
        fetchResponseStatus: action.responseStatus,
      };

    case REQUEST_UPDATE_SURFACES:
      return { ...state, isUpdating: true, updateFailed: false };

    case REQUEST_UPDATE_SURFACES_SUCCESS:
      return { ...state, isUpdating: false };

    case REQUEST_UPDATE_SURFACES_FAILED:
      return {
        ...state,
        isUpdating: false,
        isPolling: false,
        updateFailed: true,
      };

    case POLL_UPDATE_INTEREST_START:
    case POLL_INTEREST_START:
      return { ...state, isPolling: true };

    case POLL_INTEREST_COMPLETE:
      return { ...state, isPolling: false };

    case POLL_INTEREST_CANCELED:
      return { ...state, isPolling: false, fetchFailed: true };

    case SELECT_PACKAGE:
      return {
        ...state,
        ...getUserSelections(state.interest, {
          preferredPackageId: action.selectedPackage.id,
          preferredProductName: action.selectedPackage.product_name,
          preferredPackageSlug: action.selectedPackage.package_size,
          preferredPurchaseModelCode:
            state.selectedPurchaseModel?.purchase_model_code,
        }),
        previousPackage: state.selectedPackage,
      };

    case SELECT_PURCHASE_MODEL:
      return {
        ...state,
        ...getUserSelections(state.interest, {
          preferredProductName: state.selectedProduct?.name,
          preferredPackageId: state.selectedPackage && state.selectedPackage.id,
          preferredPackageSlug:
            state.selectedPackage && state.selectedPackage.package_size,
          preferredPurchaseModelCode:
            action.selectedPurchaseModel.purchase_model_code,
        }),
      };

    case CHANGE_SURFACE_ROOFING:
      return {
        ...state,
        surfaceConfigurationList: state.surfaceConfigurationList.map(
          (surfaceConfiguration) => ({
            ...surfaceConfiguration,
            roofing:
              action.surfaceConfiguration.surfaceId ===
              surfaceConfiguration.surfaceId
                ? action.roofing
                : surfaceConfiguration.roofing,
          }),
        ),
      };

    case REQUEST_ADD_CONSUMPTION:
      return {
        ...state,
        isUpdating: true,
        fetchFailed: false,
      };

    case REQUEST_ADD_CONSUMPTION_SUCCESS:
      return {
        ...state,
        userSubmittedMonthlyEnergyBill: action.monthlyEnergyBill,
        isUpdating: false,
      };

    case REQUEST_ADD_CONSUMPTION_FAILED:
      return {
        ...state,
        isUpdating: false,
        fetchFailed: true,
      };

    case TOGGLE_SURFACE:
      return {
        ...state,
        surfaceConfigurationList: [
          ...state.surfaceConfigurationList.map((surfaceConfiguration) =>
            surfaceConfiguration.surfaceId === action.referenceId
              ? {
                  ...surfaceConfiguration,
                  ...{
                    isSelected: !surfaceConfiguration.isSelected,
                  },
                }
              : surfaceConfiguration,
          ),
        ],
      };

    default:
      return state;
  }
}
